import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol } from '../types/mod';
import { getChain } from './chains';

export interface TokenInfo {
    chains: number[];
    name: TokenSymbol;
    icon: string;
    contracts: Partial<Record<ChainId, { address: string; decimal: number; wrapTokenAddress?: string }>>;
}

type Tokens = Record<TokenSymbol, TokenInfo>;

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/';

const tokens: Tokens = {
    [TokenSymbol.USDC]: {
        chains: [
            ChainId.BSC,
            ChainId.BSCTestnet,
            ChainId.Matic,
            ChainId.Fantom,
            ChainId.EthereumMainnet,
            ChainId.Rinkeby,
            ChainId.Optimism,
            ChainId.Heco,
            ChainId.Harmony,
            ChainId.Aurora,
            ChainId.AuroraTestnet,
        ],
        name: TokenSymbol.USDC,
        icon: baseURL + 'usdc.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
                decimal: 6,
            },
            [ChainId.Rinkeby]: {
                address: '0xdbd76B3B7330374386829B3B5C9317e84Ee75257',
                decimal: 6,
            },
            [ChainId.Optimism]: {
                address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
                decimal: 6,
            },
            [ChainId.BSC]: {
                address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
                decimal: 18,
            },
            [ChainId.Aurora]: {
                address: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
                decimal: 6,
            },
            [ChainId.AuroraTestnet]: {
                address: '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b',
                decimal: 6,
            },
            [ChainId.BSCTestnet]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 6,
            },
            [ChainId.Heco]: {
                address: '0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b',
                decimal: 6,
            },
            [ChainId.Matic]: {
                address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
                decimal: 6,
            },
            [ChainId.Fantom]: {
                address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
                decimal: 6,
            },
            [ChainId.Arbitrum]: {
                address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
                decimal: 6,
            },
            [ChainId.Harmony]: {
                address: '0x985458e523db3d53125813ed68c274899e9dfab4',
                decimal: 6,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
                decimal: 6,
            },
        },
    },

    [TokenSymbol.ETH]: {
        chains: [ChainId.Optimism, ChainId.Matic],
        name: TokenSymbol.ETH,
        icon: baseURL + 'eth.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
                decimal: 18,
            }, // WETH on Ethereum
            [ChainId.Optimism]: {
                address: '0x4200000000000000000000000000000000000006',
                decimal: 18,
            }, // WETH on Optimism
            [ChainId.Rinkeby]: {
                address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
                decimal: 18,
            }, // custom WETH on Rinkeby
            [ChainId.BSCTestnet]: {
                address: '0xc9DC6ed52Bf07E4048185d7B66ED7e7c5084C8F2',
                decimal: 18,
            }, // Custom WETH on BSCTestNet
            [ChainId.Arbitrum]: {
                address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
                decimal: 18,
            }, // WETH on Arbitrum
            [ChainId.Goerli]: {
                address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
                decimal: 18,
            },
            // WETH on AuroraTestnet
            [ChainId.AuroraTestnet]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
            // WETH on Aurora
            [ChainId.Aurora]: {
                address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0x8C3e3f2983DB650727F3e05B7a7773e4D641537B',
                decimal: 18,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
                decimal: 18,
            },
            [ChainId.ScrollTestL2]: {
                address: '0x3aB38EF845E86E949253Dd3a6FdA37Cc7d4Cd892',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WETH]: {
        chains: [ChainId.Matic],
        name: TokenSymbol.WETH,
        icon: baseURL + 'WETH.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0xc9DC6ed52Bf07E4048185d7B66ED7e7c5084C8F2',
                decimal: 18,
            },
            [ChainId.Goerli]: {
                address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
                decimal: 18,
            },
            [ChainId.AuroraTestnet]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
            [ChainId.Aurora]: {
                address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
                decimal: 18,
            },
            [ChainId.Rinkeby]: {
                address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
                decimal: 18,
            }, // custom WETH on Rinkeby
            [ChainId.ZkSyncAlphaTest]: {
                address: '0x8C3e3f2983DB650727F3e05B7a7773e4D641537B',
                decimal: 18,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
                decimal: 18,
            },
            [ChainId.ScrollTestL2]: {
                address: '0x3aB38EF845E86E949253Dd3a6FdA37Cc7d4Cd892',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WETH9]: {
        chains: [ChainId.ZkSyncAlphaTest],
        name: TokenSymbol.WETH,
        icon: baseURL + 'WETH.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0xc9DC6ed52Bf07E4048185d7B66ED7e7c5084C8F2',
                decimal: 18,
            },
            [ChainId.Goerli]: {
                address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
                decimal: 18,
            },
            [ChainId.AuroraTestnet]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
            [ChainId.Aurora]: {
                address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
                decimal: 18,
            },
            [ChainId.Rinkeby]: {
                address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
                decimal: 18,
            }, // custom WETH on Rinkeby
            [ChainId.ZkSyncAlphaTest]: {
                address: '0x8C3e3f2983DB650727F3e05B7a7773e4D641537B',
                decimal: 18,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
                decimal: 18,
            },
            [ChainId.ScrollTestL2]: {
                address: '0x3aB38EF845E86E949253Dd3a6FdA37Cc7d4Cd892',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.BNB]: {
        chains: [ChainId.BSC, ChainId.BSCTestnet],
        name: TokenSymbol.BNB,
        icon: baseURL + 'bnb.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                decimal: 18,
            }, // WBNB on BSC
            [ChainId.BSCTestnet]: {
                address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
                decimal: 18,
            }, // WBNB on BSCTestNet
        },
    },
    [TokenSymbol.CRO]: {
        chains: [ChainId.Cronos],
        name: TokenSymbol.CRO,
        icon: baseURL + 'cro.png',
        contracts: {
            [ChainId.Cronos]: {
                address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
                decimal: 18,
            }, // WCRO on Cronos
        },
    },
    [TokenSymbol.BNBX]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.BNBX,
        icon: baseURL + 'bnbx.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.SD]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.SD,
        icon: baseURL + 'sd.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ETC]: {
        chains: [ChainId.ETC],
        name: TokenSymbol.ETC,
        icon: baseURL + 'bnb.png',
        contracts: {
            [ChainId.ETC]: {
                address: '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WBNB]: {
        chains: [],
        name: TokenSymbol.WBNB,
        icon: baseURL + 'bnb.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                decimal: 18,
            }, // WBNB on BSC
            [ChainId.BSCTestnet]: {
                address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
                decimal: 18,
            }, // WBNB on BSCTestNet
        },
    },

    [TokenSymbol.WBIT]: {
        chains: [],
        name: TokenSymbol.WBIT,
        icon: baseURL + 'bit.png',
        contracts: {
            [ChainId.MantleTest]: {
                address: '0x69AC69b272f96F5f17DDD9da3832ad9Dc86D1d8A',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WMATIC]: {
        chains: [],
        name: TokenSymbol.WMATIC,
        icon: baseURL + 'matic.png',
        contracts: {},
    },

    [TokenSymbol.ETC]: {
        chains: [ChainId.ETC],
        name: TokenSymbol.ETC,
        icon: baseURL + 'etc.png',
        contracts: {
            [ChainId.ETC]: {
                address: '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
                decimal: 18,
            }, // WETC
        },
    },

    [TokenSymbol.WETC]: {
        chains: [ChainId.ETC],
        name: TokenSymbol.WETC,
        icon: baseURL + 'etc.png',
        contracts: {
            [ChainId.ETC]: {
                address: '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.USDT]: {
        chains: [ChainId.EthereumMainnet, ChainId.Rinkeby, ChainId.Arbitrum, ChainId.Matic, ChainId.BSCTestnet],
        name: TokenSymbol.USDT,
        icon: baseURL + 'usdt.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
                decimal: 6,
            },
            [ChainId.Rinkeby]: {
                address: '0x905f8714ebE30BF9829096043370B9BA29643D3D',
                decimal: 6,
            },
            [ChainId.Arbitrum]: {
                address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
                decimal: 6,
            },
            [ChainId.Matic]: {
                address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
                decimal: 6,
            },
            [ChainId.BSCTestnet]: {
                address: '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b',
                decimal: 6,
            },
            [ChainId.BSC]: {
                address: '0x55d398326f99059fF775485246999027B3197955',
                decimal: 18,
            },
            [ChainId.AuroraTestnet]: {
                address: '0x6a7436775c0d0B70cfF4c5365404ec37c9d9aF4b',
                decimal: 6,
            },
            [ChainId.MantleTest]: {
                address: '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b',
                decimal: 18,
            },
            [ChainId.Icplaza]: {
                address: '0x334A4801C21Bc279a63cB80E703E4da704057C13',
                decimal: 18,
            },
            [ChainId.ConfluxESpace]: {
                address: '0xfe97e85d13abd9c1c33384e796f10b73905637ce',
                decimal: 18,
            },
            [ChainId.Telos]: {
                address: '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0x65497b0FeEC71F101E5FD6F98d8B0272f8F93010',
                decimal: 6,
            },
        },
    },

    [TokenSymbol.BUSD]: {
        chains: [ChainId.BSC, ChainId.BSCTestnet],
        name: TokenSymbol.BUSD,
        icon: baseURL + 'busd.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0xd88972676f5D0997c8150A3d2C4634CbaaDD3396',
                decimal: 18,
            },
            [ChainId.AuroraTestnet]: {
                address: '0x2A2E08ea99E63482BDad8Bf69c7b69752F07736F',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.IUSD]: {
        chains: [ChainId.BSC, ChainId.EthereumMainnet, ChainId.Arbitrum, ChainId.Matic, ChainId.BSCTestnet],
        name: TokenSymbol.IUSD,
        icon: baseURL + 'iusd.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.Matic]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0x60FE1bE62fa2082b0897eA87DF8D2CfD45185D30',
                decimal: 18,
            },
            [ChainId.AuroraTestnet]: {
                address: '0xb0453a1bAf6056393897e60dfd851C61A825ef67',
                decimal: 18,
            },
            [ChainId.ETC]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.Rinkeby]: {
                address: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0xC0ddcc53b3F971EdeEc65E69D5cb01B518a7F7C8',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.APEX]: {
        chains: [ChainId.BSCTestnet, ChainId.Arbitrum],
        name: TokenSymbol.DAI,
        icon: baseURL + 'apex.svg',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x7a8176293E5dbf4a18716bB03888442cb77dc386',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x61A1ff55C5216b636a294A07D77C6F4Df10d3B56',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ESAPEX]: {
        chains: [ChainId.BSCTestnet, ChainId.Arbitrum],
        name: TokenSymbol.ESAPEX,
        icon: baseURL + 'esAPEX.svg',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x2014B354e8D3E5F49519a414b250Eda65e618e1c',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0xB86851c7F7C1b904A034F07Dd819382f8afD7893',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.DAI]: {
        chains: [ChainId.EthereumMainnet],
        name: TokenSymbol.DAI,
        icon: baseURL + 'dai.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0x6b175474e89094c44da98b954eedeac495271d0f',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.TestU]: {
        chains: [ChainId.Rinkeby],
        name: TokenSymbol.TestU,
        icon: baseURL + 'usdt.png',
        contracts: {
            [ChainId.Rinkeby]: {
                address: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247',
                decimal: 6,
            },
        },
    },

    [TokenSymbol.BIT]: {
        chains: [ChainId.EthereumMainnet, ChainId.BSCTestnet, ChainId.Rinkeby],
        name: TokenSymbol.BIT,
        icon: baseURL + 'bit.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0xac360dc0F8EF1C94Ab4034220E0A01567acbFdE4',
                decimal: 18,
            },
            [ChainId.EthereumMainnet]: {
                address: '0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5',
                decimal: 18,
            },
            [ChainId.Rinkeby]: {
                address: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
                decimal: 18,
            },
            [ChainId.MantleTest]: {
                address: '0x69AC69b272f96F5f17DDD9da3832ad9Dc86D1d8A',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.LING]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.LING,
        icon: baseURL + 'ling.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x68c0E798b749183c97239114BB76fd1e5e3DB98C',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.IZI]: {
        chains: [ChainId.EthereumMainnet],
        name: TokenSymbol.IZI,
        icon: baseURL + 'izumi.svg',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0x9ad37205d608B8b219e6a2573f922094CEc5c200',
                decimal: 18,
            },
            [ChainId.Matic]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.Rinkeby]: {
                address: '0x40E2BA87F796FaBeEe98872F2085fC020DbAd47B',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
                decimal: 18,
            },
            [ChainId.Goerli]: {
                address: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08',
                decimal: 18,
            },
            [ChainId.AuroraTestnet]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 18,
            },
            [ChainId.ETC]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.MantleTest]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 18,
            },
            [ChainId.ScrollTestL2]: {
                address: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0xA5900cce51c45Ab9730039943B3863C822342034',
                decimal: 18,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x16a9494e257703797d747540f01683952547ee5b',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.USN]: {
        chains: [ChainId.Aurora],
        name: TokenSymbol.USN,
        icon: baseURL + 'usn.png',
        contracts: {
            [ChainId.Aurora]: {
                address: '0x5183e1B1091804BC2602586919E6880ac1cf2896',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.DEVT]: {
        chains: [ChainId.EthereumMainnet],
        name: TokenSymbol.DEVT,
        icon: baseURL + 'devt.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xB5c578947de0fd71303F71F2C3d41767438bD0de',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.DDAO]: {
        chains: [ChainId.Matic],
        name: TokenSymbol.DDAO,
        icon: baseURL + 'ddao.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x90f3edc7d5298918f7bb51694134b07356f7d0c7',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.ACY]: {
        chains: [ChainId.Matic],
        name: TokenSymbol.ACY,
        icon: baseURL + 'acy.svg',
        contracts: {
            [ChainId.Matic]: {
                address: '0x8b1f836491903743fE51ACd13f2CC8Ab95b270f6',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.YIN]: {
        chains: [ChainId.Matic],
        name: TokenSymbol.YIN,
        icon: baseURL + 'yin.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
                decimal: 18,
            },
            [ChainId.EthereumMainnet]: {
                address: '0x794baab6b878467f93ef17e2f2851ce04e3e34c8',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.IONS]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.IONS,
        icon: baseURL + 'IONS.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0xa2c05b87531CD20D02790114e3B960DC98CB0B21',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.MATIC]: {
        chains: [ChainId.BSCTestnet, ChainId.Matic],
        name: TokenSymbol.MATIC,
        icon: baseURL + 'matic.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0xF8c80396d6EC96dEdc816c558273BB526b8cA847',
                decimal: 18,
            },
            [ChainId.Matic]: {
                address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.swETH]: {
        chains: [ChainId.Goerli],
        name: TokenSymbol.swETH,
        icon: baseURL + 'swETH.png',
        contracts: {
            [ChainId.Goerli]: {
                address: '0x30ebB58888E94095939e220CAb04C59Ea65ded2E',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.SWELL]: {
        chains: [ChainId.Goerli],
        name: TokenSymbol.SWELL,
        icon: baseURL + 'SWELL.png',
        contracts: {
            [ChainId.Goerli]: {
                address: '0xeD2b0E694d63F350cC124c8292ED6482b34B92DF',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.SLD]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.SLD,
        icon: baseURL + 'sld.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x45F76eD56082cd0B0A0Ad1E4513214d1219f9998',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x1ef6A7e2c966fb7C5403EFEFdE38338b1a95a084',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.DUET]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.DUET,
        icon: baseURL + 'DUET.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x5D111A3573838f6A24B4b64dbE6A234bE1e6d822',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.dWTI]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.dWTI,
        icon: baseURL + 'dWTI.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x967b61E062205C2DcbB6146b383119A8827493C3',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.DUSD]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.DUSD,
        icon: baseURL + 'dUSD.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x5682fBb54565b02a4E72Ce29C5a9B61Dee8a0819',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.USDT18]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.USDT18,
        icon: baseURL + 'usdt.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x3eC84d8bDD27Db0FD49462187b0bd8E347bBc1e7',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.KSW]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.KSW,
        icon: baseURL + 'KSW.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0xe377BA982D52C598568cE37dd146ced237FFd938',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x270178366a592bA598C2e9d2971DA65f7bAa7C86',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.REVO]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.REVO,
        icon: baseURL + 'REVO.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x1e19F04008f57344D589494C50Ff8138aD5207Ae',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0xfC279e6ff1FB1C7F5848d31561cAb27d34a2856b',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.LAND]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.LAND,
        icon: baseURL + 'LAND.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x1017D7d37169f98EED32BBB68aD79A3881174e3f',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x9A981FAcCD3B9b3B9453BFF266296fc0f2De21a2',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.FROYO]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.FROYO,
        icon: baseURL + 'froyo.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0xed2F92D6D2b936ce3Db9e046E57D9119e4A31ECb',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.NAS]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.NAS,
        icon: baseURL + 'nas.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x2E1F3D4cB35980F6339C006e64AC3a555dB6676d',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.MNW]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.MNW,
        icon: baseURL + 'MNW.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x8e596702b7e84907836776fddc56067b64147c8f',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.ARC]: {
        chains: [ChainId.AuroraTestnet],
        name: TokenSymbol.ARC,
        icon: baseURL + 'arc.png',
        contracts: {
            [ChainId.AuroraTestnet]: {
                address: '0xa341a31CCdD570cAEab465c96D64c880db609021',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.AURORA]: {
        chains: [ChainId.AuroraTestnet],
        name: TokenSymbol.AURORA,
        icon: baseURL + 'aurora.png',
        contracts: {
            [ChainId.AuroraTestnet]: {
                address: '0xcC2DE5D1A8154f7fa0e80a6732a38422D8fBEB10',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.TAP]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.TAP,
        icon: baseURL + 'tap.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x35bedbf9291b22218a0da863170dcc9329ef2563',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.MELT]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.MELT,
        icon: baseURL + 'melt.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x7eb35225995b097c84eF10501dD6E93A49bdFd63',
                decimal: 8,
            },
        },
    },
    [TokenSymbol.DMT]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.DMT,
        icon: baseURL + 'DMT.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x09CdC7E87ABd416246F1015B08B4463Abdda00B4',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.BURGER]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.BURGER,
        icon: baseURL + 'burger.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.GE]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.GE,
        icon: baseURL + 'ge.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x708F90170722f8F8775bf64d7f435A2BADBd89FD',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.RIV]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.RIV,
        icon: baseURL + 'RIV.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xCa5e32d44F1744001b5600Dc2F5F5e0BbB6E9D3e',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.COCA]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.COCA,
        icon: baseURL + 'coca.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x44516Eb3D488175128D257748610426a866937D8',
                decimal: 9,
            },
        },
    },
    [TokenSymbol.ANT]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.ANT,
        icon: baseURL + 'ant.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x76E4EFB2c78b08c3fdE3eDF98c6503dd9Df9C5E8',
                decimal: 18,
                wrapTokenAddress: '0xa0D289c752FE23043f29BD81e30D8345180bD9BB',
            },
        },
    },
    [TokenSymbol.FEVR]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.FEVR,
        icon: baseURL + 'fevr.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x82030CDBD9e4B7c5bb0b811A61DA6360D69449cc',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.aBNBc]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.aBNBc,
        icon: baseURL + 'abnbc.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ankrBNB]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.ankrBNB,
        icon: baseURL + 'abnbc.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x52f24a5e03aee338da5fd9df68d2b6fae1178827',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.ANKR]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.ANKR,
        icon: baseURL + 'ankr.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.GRI]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.GRI,
        icon: baseURL + 'gri.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xD767713e787Cb351e4daFb777DD93d54f5381D29',
                decimal: 8,
            },
        },
    },
    [TokenSymbol.ZBC]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.ZBC,
        icon: baseURL + 'zbc.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x37a56cdcD83Dce2868f721De58cB3830C44C6303',
                decimal: 9,
            },
        },
    },
    [TokenSymbol.ERA]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.ERA,
        icon: baseURL + 'era.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.GOT]: {
        chains: [ChainId.BSC],
        name: TokenSymbol.GOT,
        icon: baseURL + 'got.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xDA71E3eC0d579FED5dbABa31eEaD3cEB9e77A928',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WOO]: {
        name: TokenSymbol.WOO,
        icon: baseURL + 'woo.png',
        chains: [ChainId.BSC],
        contracts: {
            [ChainId.BSC]: {
                address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.HYT]: {
        name: TokenSymbol.HYT,
        icon: baseURL + 'HYT.png',
        chains: [ChainId.BSC],
        contracts: {
            [ChainId.BSC]: {
                address: '0xE28C51f4fa57dC44af3691c6f73D1ca578b586Be',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.MIT]: {
        name: TokenSymbol.MIT,
        icon: baseURL + 'mit.png',
        chains: [ChainId.BSC],
        contracts: {
            [ChainId.BSC]: {
                address: '0xe6906717f129427eebade5406de68cadd57aa0c0',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0xAec84500e96F90370C55657d08f375A8d05F4D8c',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.slUSDT]: {
        name: TokenSymbol.slUSDT,
        icon: baseURL + 'slusdt.png',
        chains: [ChainId.BSC],
        contracts: {
            [ChainId.BSC]: {
                address: '0x65cd2e7d7bacdac3aa9dae68fb5d548dfe1fefb5',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x65cd2e7d7bacdac3aa9dae68fb5d548dfe1fefb5',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.slUSDC]: {
        name: TokenSymbol.slUSDC,
        icon: baseURL + 'slusdc.png',
        chains: [ChainId.BSC],
        contracts: {
            [ChainId.BSC]: {
                address: '0x55a26773a8ba7fa9a8242b43e14a69488e6d2b05',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x55a26773a8ba7fa9a8242b43e14a69488e6d2b05',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.slBTC]: {
        name: TokenSymbol.slBTC,
        icon: baseURL + 'slbtc.png',
        chains: [ChainId.BSC],
        contracts: {
            [ChainId.BSC]: {
                address: '0xe04b30f233e3bc96da2401ee146a94f1c7c54917',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0xe04b30f233e3bc96da2401ee146a94f1c7c54917',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.slETH]: {
        name: TokenSymbol.slETH,
        icon: baseURL + 'sleth.png',
        chains: [ChainId.BSC],
        contracts: {
            [ChainId.BSC]: {
                address: '0xdf6964c7ccfabaace7c3f918b795fa4a894589c8',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0xdf6964c7ccfabaace7c3f918b795fa4a894589c8',
                decimal: 18,
            },
        },
    },

    // test
    [TokenSymbol.FeeA]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.FeeA,
        icon: baseURL + 'DMT.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x0c08e73Abe0Fc445e727Ca9b779D22649110f782',
                decimal: 18,
                wrapTokenAddress: '0xa52A8da39fff2f31c8b60eee4aa0426e14a2D144',
            },
        },
    },
    [TokenSymbol.FeeB]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.FeeB,
        icon: baseURL + 'DMT.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x0C2CE63c797190dAE219A92AeBE4719Dc83AADdf',
                decimal: 18,
                wrapTokenAddress: '0x5a2FEa91d21a8D53180020F8272594bf0D6F36DC',
            },
        },
    },
    [TokenSymbol.TKO]: {
        chains: [ChainId.BSCTestnet],
        name: TokenSymbol.TKO,
        icon: baseURL + 'DMT.png',
        contracts: {
            [ChainId.BSCTestnet]: {
                address: '0x965dfa46ad0a101014cecc29a18effdf175da23b',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.ICT]: {
        chains: [ChainId.Icplaza],
        name: TokenSymbol.ICT,
        icon: baseURL + 'ict.png',
        contracts: {
            [ChainId.Icplaza]: {
                address: '0xc59d478873d11CCc68F9c63571E821a253c5B605', //WICT
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WICT]: {
        chains: [ChainId.Icplaza],
        name: TokenSymbol.WICT,
        icon: baseURL + 'ict.png',
        contracts: {
            [ChainId.Icplaza]: {
                address: '0xc59d478873d11CCc68F9c63571E821a253c5B605',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.CFX]: {
        chains: [ChainId.ConfluxESpace],
        name: TokenSymbol.CFX,
        icon: baseURL + 'cfx.png',
        contracts: {
            [ChainId.ConfluxESpace]: {
                address: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WCFX]: {
        chains: [ChainId.ConfluxESpace],
        name: TokenSymbol.WCFX,
        icon: baseURL + 'cfx.png',
        contracts: {
            [ChainId.ConfluxESpace]: {
                address: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.MTR]: {
        chains: [ChainId.Meter],
        name: TokenSymbol.MTR,
        icon: baseURL + 'mtr.png',
        contracts: {
            [ChainId.Meter]: {
                address: '0x160361ce13ec33C993b5cCA8f62B6864943eb083', //WMTR
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WMTR]: {
        chains: [ChainId.Meter],
        name: TokenSymbol.WMTR,
        icon: baseURL + 'mtr.png',
        contracts: {
            [ChainId.Meter]: {
                address: '0x160361ce13ec33C993b5cCA8f62B6864943eb083',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.MTRG]: {
        chains: [ChainId.Meter],
        name: TokenSymbol.MTRG,
        icon: baseURL + 'mtrg.png',
        contracts: {
            [ChainId.Meter]: {
                address: '0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.TLOS]: {
        chains: [ChainId.Telos],
        name: TokenSymbol.TLOS,
        icon: baseURL + 'tlos.png',
        contracts: {
            [ChainId.Telos]: {
                address: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WTLOS]: {
        chains: [ChainId.Telos],
        name: TokenSymbol.WTLOS,
        icon: baseURL + 'tlos.png',
        contracts: {
            [ChainId.Telos]: {
                address: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.DEXTF]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.DEXTF,
        icon: baseURL + 'dextf.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x9929bCAC4417A21d7e6FC86F6Dae1Cc7f27A2e41',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.ZKSP]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.ZKSP,
        icon: baseURL + 'zksp.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x7eCf006f7c45149B457e9116392279CC8A630F14',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.RF]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.RF,
        icon: baseURL + 'rf.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x5f7cbcb391d33988dad74d6fd683aadda1123e4d',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.OT]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.OT,
        icon: baseURL + 'ot.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xd0ea21ba66b67be636de1ec4bd9696eb8c61e9aa',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.SPACE]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.SPACE,
        icon: baseURL + 'space.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x47260090cE5e83454d5f05A0AbbB2C953835f777',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.XSPACE]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.XSPACE,
        icon: baseURL + 'space.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x6aF43486Cb84bE0e3EDdCef93d3C43Ef0C5F63b1',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.BLADE]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.BLADE,
        icon: baseURL + 'blade.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x591CAdFaF2F5dBBD09580C8b74A4770A9255bF28',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.LSD]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.LSD,
        icon: baseURL + 'lsd.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x458A2E32eAbc7626187E6b75f29D7030a5202bD4',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.GGG]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.GGG,
        icon: baseURL + 'ggg.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x7E2A6456Bb3eCEcbdb38bC76Ad8dF9e448B15835',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.KANA]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.KANA,
        icon: baseURL + 'kana.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x26aC1D9945f65392B8E4E6b895969b5c01A7B414',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.zkUSD]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.zkUSD,
        icon: baseURL + 'zkusd.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xfC7E56298657B002b3e656400E746b7212912757',
                decimal: 6,
            },
        },
    },
    [TokenSymbol.PIKO]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.PIKO,
        icon: baseURL + 'piko.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xf8C6dA1bbdc31Ea5F968AcE76E931685cA7F9962',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.esPIKO]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.esPIKO,
        icon: baseURL + 'espiko.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xCaa5ba70830f8557fd6516Cf632b08AA4B6A528F',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.BEL]: {
        chains: [ChainId.ZkSyncEra],
        name: TokenSymbol.BEL,
        icon: baseURL + 'bel.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xB83CFB285fc8D936E8647FA9b1cC641dBAae92D9',
                decimal: 18,
            },
        },
    },
};

export default tokens;

export const tokenSymbol2token = (tokenSymbol: string, chainId: ChainId): TokenInfoFormatted => {
    const token = tokens[tokenSymbol as TokenSymbol];
    return {
        name: token.name,
        symbol: tokenSymbol as string,
        icon: token.icon,
        chainId: chainId,
        address: token.contracts[chainId]?.address ?? '',
        decimal: token.contracts[chainId]?.decimal ?? 18,
        wrapTokenAddress: token.contracts[chainId]?.wrapTokenAddress,
        custom: false,
    };
};

export const tokenAddr2Token = (tokenAddr: string, chainId: ChainId): TokenInfoFormatted => {
    const token = Object.values(tokens).find((e) => e.contracts[chainId]?.address.toUpperCase() === tokenAddr.toUpperCase());
    return {
        name: token?.name ?? '',
        symbol: token?.name ?? '',
        icon: token?.icon ?? '',
        chainId,
        address: tokenAddr,
        decimal: token?.contracts[chainId]?.decimal ?? 18,
        wrapTokenAddress: token?.contracts[chainId]?.wrapTokenAddress,
        custom: false,
    };
};

export const isGasToken = (token: TokenInfoFormatted, chainId: ChainId): boolean => {
    if (!token || !chainId) {
        return false;
    }
    const chain = getChain(chainId);
    const chainTokenAddress = chain?.token.address ?? '';
    if (
        token.chainId === chainId &&
        token.symbol === chain?.token.symbol &&
        token.address.toLowerCase() === chainTokenAddress.toLowerCase()
    ) {
        return true;
    }
    return false;
};

export const isGasOrWrappedGasToken = (token: TokenInfoFormatted, chainId: ChainId): boolean => {
    if (!token || !chainId) {
        return false;
    }
    const chain = getChain(chainId);
    const chainTokenAddress = chain?.token.address ?? '';
    if (token.chainId === chainId && token.address.toLowerCase() === chainTokenAddress.toLowerCase()) {
        return true;
    }
    return false;
};

export const isWrappedGasToken = (token: TokenInfoFormatted, chainId: ChainId): boolean => {
    if (!token || !chainId) {
        return false;
    }
    const chain = getChain(chainId);
    const chainTokenAddress = chain?.token.address ?? '';
    if (
        token.chainId === chainId &&
        token.symbol !== chain?.token.symbol &&
        token.address.toLowerCase() === chainTokenAddress.toLowerCase()
    ) {
        return true;
    }
    return false;
};

export const getGasToken = (chainId: ChainId): TokenInfoFormatted => {
    const chain = getChain(chainId);
    return tokenSymbol2token(chain?.token.symbol ?? 'BNB', chainId);
};

export const getWrappedGasTokenIfExists = (chainId: ChainId): TokenInfoFormatted => {
    const chain = getChain(chainId);
    const token = tokenSymbol2token(chain?.token.symbol ?? 'BNB', chainId);
    const wrappedSymbol = chain?.wrappedTokenSymbol ?? token.symbol;
    return { ...token, symbol: wrappedSymbol as TokenSymbol };
};
