import { Image, useColorMode, Text, BoxProps, VStack, HStack } from '@chakra-ui/react';
import { TRADE_LOADING_HINT } from '../../config/trade/config';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { Modal } from '../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { i_h4, i_text_copy } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    content: string;
} & BoxProps;
export const TradeLoadingModal: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { isOpen, onClose, content, ...rest } = props;
    const { chainId } = useWeb3WithDefault();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '341px', sm: '540px' }}
            minH={{ base: '383px', sm: '478px' }}
            title=""
            isShowClose={false}
            {...rest}
        >
            <VStack h={{ base: '300px', sm: '350px' }} spacing="10px" pt={{ base: '30px', sm: '50px' }}>
                <Image w="60px" h="60px" src={colorTheme('/assets/loading/loading.gif', '/assets/loading/darkLoading.gif')}></Image>
                <Text
                    className={i_h4}
                    mt="38px !important"
                    color={colorTheme('#2E0069', '#E8D6FF')}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                >
                    {content}
                </Text>

                <Text className={i_text_copy} color={colorTheme('#5D2DAC', '#C29CFF')}>
                    Please sign in your wallet
                </Text>
                {TRADE_LOADING_HINT && TRADE_LOADING_HINT[chainId] && (
                    <HStack w="100%" bg={colorTheme('#F6F4FF', '#3E2C76')} borderRadius="12px" py="14px" px="24px" mt="auto !important">
                        <Image
                            boxSize="17px"
                            src={colorTheme('/assets/swap/LoadingModal/info.svg', '/assets/swap/LoadingModal/darkInfo.svg')}
                            fallbackSrc={colorTheme('/assets/swap/LoadingModal/info.svg', '/assets/swap/LoadingModal/darkInfo.svg')}
                        ></Image>
                        <Text className={i_text_copy} color={colorTheme('#754FFF', '#DBD1FF')}>
                            {TRADE_LOADING_HINT[chainId]}
                        </Text>
                    </HStack>
                )}
            </VStack>
        </Modal>
    );
};
